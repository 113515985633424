import React, { useState } from 'react';
import { Button, Checkbox, message } from 'antd';
import Card from '../Card';
import TextSection from './TextSection';
import { eulaLastUpdate } from '.';

const pdfPath =
  'https://firebasestorage.googleapis.com/v0/b/aloa-devpay.appspot.com/o/End%20User%20License%20Agreement%20(EULA).pdf?alt=media&token=511b187b-a529-4f90-b0b1-cba899c8b897';

function ContentSection({ currentUser, history, signup, sign, hidePage }) {
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const eulaIsAlreadySigned =
    currentUser.eula &&
    currentUser.eula.signed &&
    new Date(currentUser.eula.timestamp) > eulaLastUpdate;

  const handleCheckChange = e => {
    const isChecked = e.target.checked;

    setChecked(isChecked);

    if (isChecked && !scrolledToBottom) {
      message.error(
        'Sorry, you must read through the entire End User License Agreement before you can accept it.'
      );
      setTimeout(() => setChecked(!isChecked), 1000);
    }
  };

  const onClickAccept = () => {
    if (!checked) {
      message.error('Please accept the End User License Agreement to continue.');
    } else if (!currentUser) {
      message.error(
        `Sorry it doesn't look like you're signed in. First sign in, then you'll be able to accept the End User License Agreement`
      );
    } else {
      setLoading(true);
      sign()
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  };

  const onClickDecline = () => {
    message.error('Sorry, you have to accept the End User License Agreement to continue.');
  };

  return (
    <Card>
      <div style={{ fontWeight: '750' }}>
        Please read this in its entirety and only press accept if you have read the agreement and
        agreed to its terms outlined below.
      </div>
      <TextSection onScrollToBottom={() => setScrolledToBottom(true)} />
      <div style={{ width: '100%' }}>
        <a
          style={{ float: 'right', marginBottom: 20 }}
          href={pdfPath}
          target="_blank"
          rel="noopener noreferrer"
        >
          Save or Print
        </a>
        <Checkbox
          style={{ float: 'left' }}
          disabled={eulaIsAlreadySigned}
          checked={checked || eulaIsAlreadySigned}
          onChange={handleCheckChange}
        >
          {!eulaIsAlreadySigned
            ? 'I have fully read and agree to the End User License Agreement as stated above'
            : `You accepted this EULA on ${new Date(currentUser.eula.timestamp).toLocaleDateString(
                'en-US',
                { year: 'numeric', month: 'long', day: 'numeric' }
              )}`}
        </Checkbox>
      </div>
      <div style={{ textAlign: 'center', clear: 'both' }}>
        {eulaIsAlreadySigned && (
          <Button
            onClick={() => {
              history.push('/');
              hidePage();
            }}
            style={{ marginTop: 10, marginRight: 20 }}
          >
            Home
          </Button>
        )}
        {!eulaIsAlreadySigned && (
          <>
            <Button
              onClick={onClickAccept}
              disabled={!(scrolledToBottom && checked)}
              loading={loading}
              type="primary"
              style={{ marginTop: 20, marginRight: 10 }}
            >
              Accept
            </Button>
            <Button onClick={onClickDecline}>Decline</Button>
          </>
        )}
      </div>
    </Card>
  );
}

export default ContentSection;
