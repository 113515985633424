import { env } from '../constants';

const fetch = require('node-fetch');
const CryptoJS = require('crypto-js');

const URL = 'https://api.aloa.co';
const apiKey = 'k8Hr3dqsvS2hRMhO0OqHAzLcNY6XKPq3';

const BASE_ENDPOINT = env.stripeEndpoint;

const newCustomer = customerData =>
  new Promise((resolve, reject) => {
    makeRequest('/customers/new', 'POST', {}, customerData)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const getCustomer = customerId =>
  new Promise((resolve, reject) => {
    makeRequest('/customers', 'GET', { customerId }, {})
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const addPlaidSource = (customerId, accountId, plaidToken) =>
  new Promise((resolve, reject) => {
    const bodyParams = {
      accountId,
      plaidToken,
    };

    makeRequest(`/customers/${customerId}/addPlaidSource`, 'POST', {}, bodyParams)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const addSource = (customerId, sourceId) =>
  new Promise((resolve, reject) => {
    const bodyParams = {
      sourceId,
    };

    makeRequest(`/customers/${customerId}/addSource`, 'POST', {}, bodyParams)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const verifySource = (customerId, sourceId, amounts) =>
  new Promise((resolve, reject) => {
    const bodyParams = {
      sourceId,
      amounts,
    };

    makeRequest(`/customers/${customerId}/verifySource`, 'POST', {}, bodyParams)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const removeSource = (customerId, sourceId) =>
  new Promise((resolve, reject) => {
    const bodyParams = {
      sourceId,
    };

    makeRequest(`/customers/${customerId}/removeSource`, 'POST', {}, bodyParams)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

const newCharge = (customer, source, amount, currency, description) =>
  new Promise((resolve, reject) => {
    const urlParams = [customer, source];

    if (amount > 0) {
      const bodyParams = {
        amount,
        currency,
        description,
      };

      makeRequest('/charges/new', 'POST', urlParams, bodyParams)
        .then(response => resolve(response))
        .catch(error => reject(error));
    } else {
      reject(Error("Hmm something's not right... Your milestone amount should greater than $0"));
    }
  });

const makeRequest = (endPoint, method, urlParams, bodyParams) =>
  new Promise((resolve, reject) => {
    const tokenFetchParams = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        'Request-Nonce': 'true',
      },
    };

    fetch(`${URL + BASE_ENDPOINT}/token`, tokenFetchParams)
      .then(response => response.json())
      .then(responseJson => {
        const { token } = responseJson;
        const { date } = responseJson;

        if (token) {
          let url = `${URL + BASE_ENDPOINT}/stripe${endPoint}`;

          if (urlParams.length > 0) {
            urlParams.forEach(param => {
              url = url.concat(`/${param}`);
            });
          }

          const signature = CryptoJS.AES.encrypt(
            JSON.stringify(bodyParams),
            apiKey + token.toString()
          );

          const fetchParams = {
            method,
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
              Token: token,
              Signature: signature.toString(),
              Date: date,
            },
          };

          if (method !== 'GET') {
            fetchParams.body = JSON.stringify(bodyParams);
          }

          fetch(url, fetchParams)
            .then(response => response.json())
            .then(json => {
              handleStripeResponse(json)
                .then(respone => resolve(respone))
                .catch(error => reject(error));
            })
            .catch(error => {
              reject(error);
            });
        } else {
          reject(Error('Error receiving token'));
        }
      })
      .catch(error => {
        reject(error);
      });
  });

const handleStripeResponse = response =>
  new Promise((resolve, reject) => {
    if (!response.statusCode || response.statusCode === 200) {
      resolve(response);
    } else {
      console.error(`Error in stripe ${response.stack}`);
      reject(response);
    }
  });

export default {
  newCustomer,
  getCustomer,
  addPlaidSource,
  addSource,
  removeSource,
  newCharge,
  verifySource,
};
