/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RxAvatar } from 'react-icons/rx';
import { MdOutlineMail } from 'react-icons/md';
import { BsFillCheckCircleFill, BsLock, BsPlusCircle, BsXCircleFill } from 'react-icons/bs';
import { Form, Input, Button, message, Upload, Alert, Popover, Spin } from 'antd';
import * as typeformEmbed from '@typeform/embed';
import Redirect from './Redirect';
import Card from '../Card';
import aloaSmallLineLogo from '../../images/aloa_small_line.png';
import { createUser, getInviteForCode } from '../../api/users';
import Loading from '../Loading';
import { id, getBase64, makeImageData } from '../../utils';
import firebase from '../../firebase';
import Toggle, { On, Off, featureTypes } from '../FeatureToggle';

const FormItem = Form.Item;

export default function SignUp({ inviteCode, auth, history }) {
  const [newUser, setNewUser] = useState({ image: '' });
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageDownloadUrl, setImageDownloadUrl] = useState('');
  const [loggedIn, setLoggedIn] = useState(auth.currentUser);
  const [pageLoaded, setPageLoaded] = useState(!inviteCode);
  const [passwordHelpVisible, setPasswordHelpVisible] = useState(false);
  const [redirectToOnboarding, setRedirectToOnboarding] = useState(false);

  useEffect(() => {
    if (inviteCode) {
      getInviteForCode(inviteCode)
        .then(invite => {
          console.log(invite);
          if (invite.email) {
            console.log('invite.payment_onboarding', invite);
            setRedirectToOnboarding(invite.show_payment_method_onboarding || false);
            setNewUser({
              ...newUser,
              ...{
                email: invite.email,
                show_payment_method_onboarding: invite.show_payment_method_onboarding || false,
              },
            });
          } else {
            setInvalid(true);
          }
          setPageLoaded(true);
        })
        .catch(error => {
          console.error(error);
          setInvalid(true);
          setPageLoaded(true);
        });
    }
  }, [inviteCode]);

  // eslint-disable-next-line consistent-return
  auth.onUserUpdate(user => {
    if (user) {
      setLoggedIn(true);
    }
  });

  const createAccount = () => {
    let goodToGo = true;
    console.log('WOW');

    if (!newUser.name) {
      goodToGo = false;
      console.log('Missing Name');
      message.error('Missing Name');
    } else if (!newUser.company) {
      goodToGo = false;
      message.error('Missing Company');
    } else if (!newUser.email) {
      goodToGo = false;
      message.error('Missing Email');
    } else if (!newUser.email.includes('@')) {
      goodToGo = false;
      message.error('Email format invalid');
    } else if (!newUser.password) {
      goodToGo = false;
      message.error('Missing password');
    } else if (!newUser.passwordConfirm) {
      goodToGo = false;
      message.error('Missing confirm password');
    } else if (newUser.password !== newUser.passwordConfirm) {
      goodToGo = false;
      message.error('Passwords do not match');
    } else if (!eightChars || !atLeastThree) {
      message.error('Password strength requirements not met');
    }

    if (imageDownloadUrl) {
      const imageData = makeImageData(imageDownloadUrl);
      newUser.image = imageData.image;
      newUser.image_thumbnail = imageData.image_thumbnail;
      setNewUser({ ...newUser });
    }

    // setLoading(goodToGo);

    if (goodToGo) {
      createUser(newUser)
        .then(user => {
          auth.login(user.email, newUser.password, true, redirectToOnboarding);
        })
        .catch(error => {
          setLoading(false);
          message.error(error.message);
        });
    }
  };

  const submiSignupForm = event => {
    console.log('WOW');
    createAccount();
  };

  const handleChange = (event, type) => {
    newUser[type] = event.target.value;
    setNewUser({ ...newUser });
  };

  function beforeUpload(file) {
    /*
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
          console.log("Image must be smaller than 5 MB!")
        message.error('Image must smaller than 5MB!');
      } */
    return true;
  }

  const handleChangeImage = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      const storageRef = firebase.storage().ref();
      const randomId = id();

      const uploadTask = storageRef
        .child(`tempFolder/${randomId}.jpg`)
        .put(info.file.originFileObj);

      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log('Upload is running');
              break;
            default:
              break;
          }
        },
        error => {
          message.error(error.message);
          setLoading(false);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            setImageDownloadUrl(downloadURL);
            getBase64(info.file.originFileObj, url => {
              setImageUrl(url);
              setLoading(false);
            });
          });
        }
      );
    }
  };

  const eightChars = newUser.password && newUser.password.length > 7;
  const hasUppercase = newUser.password && newUser.password.replace(/[^A-Z]/g, '').length > 0;
  const hasLowercase = newUser.password && newUser.password.replace(/[^a-z]/g, '').length > 0;
  const hasNumbers = newUser.password && newUser.password.replace(/[^0-9]/g, '').length > 0;
  const hasSpecial =
    newUser.password && /[~@`!#$%^&*+=\-[\]\\';,/{}()_|\\":<>?]/g.test(newUser.password);
  const atLeastThree =
    [hasUppercase, hasLowercase, hasNumbers, hasSpecial].filter(value => value).length >= 3;

  const passwordHelp = (
    <div>
      <div style={{ color: eightChars ? 'rgb(77, 217, 100)' : 'rgb(221, 53, 0)' }}>
        {eightChars ? (
          <BsFillCheckCircleFill style={{ marginRight: '5px' }} />
        ) : (
          <BsXCircleFill style={{ marginRight: '5px' }} />
        )}
        At least 8 characters in length
      </div>
      <div style={{ color: atLeastThree ? 'rgb(77, 217, 100)' : 'rgb(221, 53, 0)' }}>
        {atLeastThree ? (
          <BsFillCheckCircleFill style={{ marginRight: '5px' }} />
        ) : (
          <BsXCircleFill style={{ marginRight: '5px' }} />
        )}
        Contain at least 3 of the following 4 types of characters:
      </div>
      <ul style={{ paddingLeft: '30px' }}>
        <li
          type={hasLowercase ? 'none' : 'bullet'}
          style={{
            color: hasLowercase ? 'rgb(77, 217, 100)' : 'rgba(0, 0, 0, 0.65)',
            marginLeft: hasLowercase ? '-20px' : 0,
          }}
        >
          {hasLowercase && <BsFillCheckCircleFill style={{ marginRight: '5px' }} type="check" />}
          Lower case letters (a-z)
        </li>
        <li
          type={hasUppercase ? 'none' : 'bullet'}
          style={{
            color: hasUppercase ? 'rgb(77, 217, 100)' : 'rgba(0, 0, 0, 0.65)',
            marginLeft: hasUppercase ? '-20px' : 0,
          }}
        >
          {hasUppercase && <BsFillCheckCircleFill style={{ marginRight: '5px' }} type="check" />}
          Upper case letters (A-Z)
        </li>
        <li
          type={hasNumbers ? 'none' : 'bullet'}
          style={{
            color: hasNumbers ? 'rgb(77, 217, 100)' : 'rgba(0, 0, 0, 0.65)',
            marginLeft: hasNumbers ? '-20px' : 0,
          }}
        >
          {hasNumbers && <BsFillCheckCircleFill style={{ marginRight: '5px' }} type="check" />}
          Numbers (i.e. 0-9)
        </li>
        <li
          type={hasSpecial ? 'none' : 'bullet'}
          style={{
            color: hasSpecial ? 'rgb(77, 217, 100)' : 'rgba(0, 0, 0, 0.65)',
            marginLeft: hasSpecial ? '-20px' : 0,
          }}
        >
          {hasSpecial && <BsFillCheckCircleFill style={{ marginRight: '5px' }} type="check" />}
          Special characters (e.g. !@#$%^&*)
        </li>
      </ul>
    </div>
  );

  const tf = typeformEmbed.makePopup('https://form.typeform.com/to/CAQAM2?ref=accounts-signup', {
    hideFooter: true,
    hideHeaders: true,
    autoClose: 5,
    opacity: 100,
  });

  if (!pageLoaded) {
    return <Loading />;
  }

  if (!loggedIn) {
    return (
      <div className="registerArea">
        <Toggle feature={featureTypes.OPEN_SIGN_UP} on={inviteCode}>
          <On>
            {invalid && (
              <div>
                <Alert
                  style={{ marginBottom: '-60px' }}
                  message="Invalid Invite URL"
                  description="The invite URL is not valid, please double check the URL you are using or make sure the invite was not revoked"
                  type="error"
                />
              </div>
            )}
            <img
              src={aloaSmallLineLogo}
              style={{ height: '40px', marginBottom: '40px', marginTop: '100px' }}
              alt="Logo"
            />
            <Card style={{ width: '100%' }}>
              <Form>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    // action="https://jsonplaceholder.typicode.com/posts"`
                    action={`${process.env.REACT_APP_API_ENDPOINT}/v1/auth/u/`}
                    beforeUpload={beforeUpload}
                    onChange={handleChangeImage}
                  >
                    {imageUrl ? (
                      <img style={{ width: '100px', margin: 'auto' }} src={imageUrl} alt="avatar" />
                    ) : (
                      <div>
                        {loading ? <Spin /> : <BsPlusCircle />}

                        <div className="ant-upload-text">Choose Profile Pic</div>
                      </div>
                    )}
                  </Upload>
                </div>

                <FormItem>
                  <Input
                    value={newUser.name}
                    onChange={e => handleChange(e, 'name')}
                    prefix={<RxAvatar className="signup-icon" />}
                    placeholder="Name"
                  />
                </FormItem>
                <FormItem>
                  <Input
                    value={newUser.company}
                    onChange={e => handleChange(e, 'company')}
                    prefix={<RxAvatar className="signup-icon" type="user" />}
                    placeholder="Company Name"
                  />
                </FormItem>
                <FormItem>
                  <Input
                    value={newUser.email}
                    onChange={e => handleChange(e, 'email')}
                    prefix={<MdOutlineMail className="signup-icon" />}
                    placeholder="Email"
                    disabled={inviteCode}
                  />
                </FormItem>
                <FormItem>
                  <Popover
                    open={passwordHelpVisible}
                    content={passwordHelp}
                    title="Password Requirements"
                  >
                    <Input
                      onFocus={() => setPasswordHelpVisible(true)}
                      onBlur={() =>
                        setPasswordHelpVisible(
                          (newUser.password || false) &&
                            newUser.password.length > 0 &&
                            !(eightChars && atLeastThree)
                        )
                      }
                      value={newUser.password}
                      onChange={e => handleChange(e, 'password')}
                      prefix={<BsLock className="signup-icon" />}
                      type="password"
                      placeholder="Password"
                    />
                  </Popover>
                </FormItem>
                <FormItem>
                  <Input
                    value={newUser.passwordConfirm}
                    onChange={e => handleChange(e, 'passwordConfirm')}
                    type="password"
                    placeholder="Confirm Password"
                    prefix={<BsLock className="signup-icon" />}
                  />
                </FormItem>

                <FormItem>
                  <Button
                    type="primary"
                    onClick={() => {
                      submiSignupForm();
                    }}
                    loading={loading}
                    block
                  >
                    Create Account
                  </Button>
                </FormItem>
              </Form>
            </Card>
            Have an account?
            <Link to="/login"> Sign in </Link>
          </On>
          <Off>
            {' '}
            <img
              src={aloaSmallLineLogo}
              style={{ height: '40px', marginBottom: '40px', marginTop: '100px' }}
              alt="Logo"
            />
            <Card style={{ width: '100%' }}>
              <p style={{ fontWeight: '400', fontSize: '15px' }}>
                Thanks for checking us out! Currently our platform is only for our clients to use.
                <br />
                <br />
                If you'd like to learn more about the platform or would like to use the platform for
                your client work, click the button below. ✌️
              </p>
              <div style={{ textAlign: 'center' }}>
                <Button
                  type="primary"
                  onClick={() => {
                    tf.open();
                  }}
                >
                  Get Started
                </Button>
              </div>
            </Card>
          </Off>
        </Toggle>
      </div>
    );
  }

  return <Redirect logoutUser={auth.logout} history={history} />;
}
