import React from 'react';
import { createRoot } from 'react-dom/client';
import makeRoutes from './Routes';
import AppContextProvider from './AppContextProvider';
import Auth from './Auth';
import registerServiceWorker from './registerServiceWorker';
import { auth } from './firebase';

const routes = makeRoutes();

const auth0 = new Auth();
auth0.checkSession().catch(({ error }) => {
  if (error === 'login_required') {
    auth.signOut();
  }
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<AppContextProvider>{routes}</AppContextProvider>);

registerServiceWorker();
