import React from 'react';

export default ({ children, style }) => {
  return (
    <div
      className="card"
      style={{
        marginBottom: 30,
        boxShadow: '0 1px 5px 0 rgba(0,0,0,.1)',
        borderRadius: '8px',
        padding: '20px',
        ...style,
      }}
    >
      <div
        style={{
          textAlign: 'left',
          fontFamily: 'Avenir Next',
          fontSize: '14px',
          fontWeight: 400,
          color: '#7d7d7d',
          textDecoration: 'none',
          marginTop: '2px',
        }}
      >
        {children}
      </div>
    </div>
  );
};
