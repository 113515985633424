import React from 'react';
import { Button } from 'antd';
import successImage from '../../../images/payment_success.png';
import { FaRegCheckCircle } from 'react-icons/fa';

export default function CreditCardComplete({ cancelActive, complete, microDeposit }) {
  if (microDeposit) {
    return (
      <div className="flex flex-col gap-4">
        <FaRegCheckCircle className="h-12 w-12 text-green-600" />

        <div className="text-xl font-semibold">Micro deposits initiated</div>
        <div className="text-base text-gray-600">
          We&apos;ve initiated two small deposits to your bank account. It may take 1-2 business
          days for them to appear in your bank account. You will use these 2 numbers (in cents) to
          verify your bank account.
        </div>

        <div className="text-xl">Example below 👇</div>

        <div className="p-4 bg-white shadow-lg rounded-lg">
          <div className="flex items-center mb-4">
            <svg
              className="h-4 w-4 text-gray-700 mr-2"
              focusable="false"
              viewBox="0 0 16 16"
              fill="currentColor"
            >
              <path d="m1.01934059 6c-.35197409.00643829-.69672471-.17354127-.88520182-.5-.27613661-.47829262-.11226487-1.08988303.36601777-1.3660254.31885509-.18409492 2.81880291-1.56208645 7.49984346-4.1339746 4.6810405 2.57188815 7.1809884 3.94987968 7.4998435 4.1339746.4782826.27614237.6421543.88773278.3660177 1.3660254-.1884771.32645873-.5332277.50643829-.8852018.5zm9.98065941 8v-6.5h3v6.5h.999833c.5522848 0 1 .4477153 1 1v1h-15.99966603v-1c0-.5522847.44771525-1 1-1h.99983303v-6.5h3v6.5h1.5v-6.5h3v6.5z" />
            </svg>
            <span className="text-xs text-gray-600 font-mono">••••1111 example bank statement</span>
          </div>
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Transaction
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-800 font-mono">
                  <span className="text-blue-600">STRIPECH1</span>
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-blue-600 font-mono">
                  $0.25
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-800 font-mono">
                  ACH Credit
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-800 font-mono">
                  <span className="text-blue-600">STRIPECH2</span>
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-blue-600 font-mono">
                  $0.39
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-800 font-mono">
                  ACH Credit
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-500 font-mono">
                  Groceries
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-500 font-mono">
                  $56.12
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-500 font-mono">
                  Visa
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-row justify-end w-full mt-5">
          <button
            className="bg-blue-600 hover:bg-blue-800 font-medium text-sm text-white px-6 py-2 rounded-lg shadow-lg cursor-pointer border-none"
            onClick={cancelActive}
            type="button"
          >
            Complete
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 items-center justify-center p-6">
      <FaRegCheckCircle className="h-12 w-12 text-green-600" />

      <div className="flex flex-col gap-2 items-center justify-center text-center">
        <div className="text-xl font-semibold">{`🙌  Successfully linked your ${complete}`}</div>
        <div className="text-base text-gray-600">
          You can now use your payment method to pay for future invoices.
        </div>
      </div>
      <button
        className="bg-blue-600 hover:bg-blue-800 font-medium text-sm text-white px-6 py-2 rounded-lg shadow-lg cursor-pointer border-none"
        onClick={cancelActive}
        type="button"
      >
        Complete
      </button>
    </div>
  );
}
