import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { Avatar, Menu, Drawer } from 'antd';
import logo from '../../images/aloa_small_line.png';
import hamburger from '../../images/hamburger.svg';
import './TopBar.css';

export default function TopBar({ currentUser }) {
  const name = currentUser ? currentUser.name : false;
  let initials = name ? name.match(/\b\w/g) || [] : [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

  const [visible, setVisible] = useState(false);

  return (
    <div>
      {currentUser && currentUser.devEnabled && (
        <div className="development-mode-label">Development Mode</div>
      )}

      <div className="appbar flex px-8 py-3 items-center">
        <MediaQuery minWidth={701}>
          {currentUser ? (
            <div className="flex items-center" style={{ flex: 1 }}>
              <div style={{ display: 'inline-block' }}>
                <a href={process.env.REACT_APP_MANAGE_URL}>
                  <img src={logo} className="h-8" alt="Logo" />
                </a>
              </div>

              <div style={{ display: 'inline-block', marginLeft: '50px' }}>
                <a
                  className="app-link no-underline hover:text-blue-500"
                  href={process.env.REACT_APP_MANAGE_URL}
                >
                  Manage
                </a>
              </div>
              <div style={{ display: 'inline-block', marginLeft: '25px' }}>
                <a
                  className="app-link no-underline hover:text-blue-400"
                  href={process.env.REACT_APP_PAY_URL}
                >
                  Pay
                </a>
              </div>
            </div>
          ) : (
            <Link to="/login">Login</Link>
          )}
        </MediaQuery>

        <MediaQuery maxWidth={700}>
          <div style={{ flex: 1 }}>
            <div
              style={{ display: 'inline-block' }}
              onClick={() => setVisible(true)}
              onKeyDown={() => setVisible(true)}
              role="button"
              tabIndex={0}
            >
              <img style={{ width: '20px' }} src={hamburger} alt="" />
            </div>
            <Drawer
              title="Menu"
              placement="left"
              closable
              onClose={() => setVisible(false)}
              open={visible}
            >
              <Menu mode="inline" defaultSelectedKeys={['pay']}>
                <Menu.Item key="manage">
                  <a href={process.env.REACT_APP_MANAGE_URL}>Manage</a>
                </Menu.Item>
                <Menu.Item key="pay">
                  <a href={process.env.REACT_APP_MANAGE_URL}>Pay</a>
                </Menu.Item>
                <Menu.Item key="search">
                  <a href={process.env.REACT_APP_PARTNERS_URL}>Partners</a>
                </Menu.Item>
              </Menu>
            </Drawer>

            <div style={{ display: 'inline-block' }}>
              <a href={`${process.env.REACT_APP_MANAGE_URL}`}>
                <img src={logo} alt="Logo" height="28" />
              </a>
            </div>
          </div>
        </MediaQuery>
        <div className="account-section">
          <div className="user-text-section">
            <Link className="user-name" to="/">
              {currentUser.name}
            </Link>
            <div className="user-company">{currentUser.company}</div>
          </div>

          <div className="user-image-section">
            <Link to="/">
              {currentUser.image && currentUser.image !== '' ? (
                <img className="user-image" src={currentUser.image} alt="" />
              ) : (
                <Avatar className="user-initials">{initials}</Avatar>
              )}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
