import React, { useState } from 'react';
import PaymentCard from './PaymentCard';
import SettingsCard from './SettingsCard';

export default function PaymentMethodCards({
  currentUser,
  id,
  showAddPayment,
  verifySource,
  deletePaymentMethod,
  refreshUser,
}) {
  return (
    <SettingsCard name="Payment Methods">
      <div className="flex flex-col gap-4 items-start w-full">
        {currentUser.payment_methods &&
          currentUser.payment_methods.map(method => (
            <PaymentCard
              key={method.source_id}
              method={method}
              verifySource={verifySource}
              currentUser={currentUser}
              id={id}
              deletePaymentMethod={deletePaymentMethod}
              refreshUser={refreshUser}
            />
          ))}

        <div className="flex flex-row justify-between items-center w-full p-2">
          <div
            onClick={() => showAddPayment()}
            onKeyPress={() => showAddPayment()}
            style={{
              color: '#1890ff',
              fontFamily: 'Sofia Pro',
              fontSize: '15px',
              cursor: 'pointer',
            }}
            role="button"
            tabIndex={0}
          >
            Add Payment Method
          </div>
        </div>
      </div>
    </SettingsCard>
  );
}
