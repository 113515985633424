import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from 'antd';
import CreditCardForm from './CreditCardForm';
import { keys } from '../../../../constants';
import { TbCircleChevronLeft } from 'react-icons/tb';

export default function AddCreditCard({ currentUser, handleAddCreditCard, cancelActive }) {
  const apiKey = keys.stripe;
  const stripePromise = loadStripe(apiKey);
  return (
    <div className="font-['Avenir_Next']">
      <div className="flex flex-row gap-2 items-center justify-start w-full">
        <div
          onClick={cancelActive}
          onKeyPress={cancelActive}
          role="button"
          tabIndex={0}
          className="flex flex-row gap-2 items-center cursor-pointer text-neutral-500 hover:text-purple-800 text-[16px]"
        >
          <TbCircleChevronLeft />
          <div>Back</div>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-center pt-8">
        <div className="text-xl font-medium">💳 Add a credit card</div>
      </div>

      <div className="w-full flex flex-col items-center justify-center">
        <Elements stripe={stripePromise}>
          <CreditCardForm ccReceived={handleAddCreditCard} user={currentUser} />
        </Elements>
      </div>
    </div>
  );
}
