import React, { useState } from 'react';
import { countries } from 'country-data';

import { Form, Input, Radio, Button, Select, message } from 'antd';
import { isArray } from 'util';
import { TbCircleChevronLeft } from 'react-icons/tb';

const { Option } = Select;

export default function BankForm({ submit, children, cancelActive }) {
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [confirmRoutingNumber, setConfirmRoutingNumber] = useState('');
  const [type, setType] = useState('individual');
  const [country, setCountry] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    if (!accountName) {
      message.error('Account name required');
      setLoading(false);
    } else if (!accountNumber) {
      message.error('Account number required');
      setLoading(false);
    } else if (!confirmAccountNumber) {
      message.error('Confirm account number');
      setLoading(false);
    } else if (accountNumber !== confirmAccountNumber) {
      message.error('Account numbers do not match');
      setLoading(false);
    } else if (!routingNumber) {
      message.error('Routing number required');
      setLoading(false);
    } else if (!confirmRoutingNumber) {
      message.error('Confirm routing number required');
      setLoading(false);
    } else if (routingNumber !== confirmRoutingNumber) {
      message.error('Routing numbers do not match');
      setLoading(false);
    } else {
      submit({
        accountName,
        accountNumber,
        confirmAccountNumber,
        routingNumber,
        type,
        country: country.key,
      })
        .then(() => {
          message.success(
            'Added your bank account! Now just wait 1-2 business days to see the verification charges, and it can be approved 👍'
          );
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const accountNumbersMatch = () => {
    if (accountNumber && confirmAccountNumber) {
      return accountNumber === confirmAccountNumber;
    }
    return true;
  };

  const routingNumbersMatch = () => {
    if (routingNumber && confirmRoutingNumber) {
      return routingNumber === confirmRoutingNumber;
    }
    return true;
  };

  const countryOptions = [];
  Object.keys(countries).map(key => {
    if (key.length === 2 && !isArray(countries[key])) {
      return countryOptions.push(<Option key={key}>{countries[key].name}</Option>);
    }
    return null;
  });

  return (
    <div className="flex flex-col gap-2 items-start w-full">
      <div
        onClick={cancelActive}
        onKeyPress={cancelActive}
        role="button"
        tabIndex={0}
        className="flex flex-row gap-2 items-center cursor-pointer text-neutral-500 hover:text-purple-800 text-[16px]"
      >
        <TbCircleChevronLeft />
        <div>Back</div>
      </div>
      <div className="text-base">Account Holder Name</div>
      <Input
        className="border-solid border-neutral-200 px-2 py-3 rounded-lg shadow-sm w-full bg-white"
        value={accountName}
        onChange={event => setAccountName(event.target.value)}
        placeholder="Account Holder Name"
      />

      <div className="text-base">Account Number</div>
      <Input
        className="border-solid border-neutral-200 px-2 py-3 rounded-lg shadow-sm w-full bg-white"
        value={accountNumber}
        onChange={event => setAccountNumber(event.target.value)}
        placeholder="Account Number"
      />

      <div className="text-base">Confirm Account Number</div>
      <Input
        className={`border-solid border-neutral-200 px-2 py-3 rounded-lg shadow-sm w-full bg-white ${
          accountNumbersMatch() ? '' : 'border-error'
        }`}
        value={confirmAccountNumber}
        onChange={event => setConfirmAccountNumber(event.target.value)}
        placeholder="Confirm Account Number"
      />
      {!accountNumbersMatch() && <div className="text-error">Account numbers do not match</div>}

      <div className="text-base">Routing Number</div>
      <Input
        className="border-solid border-neutral-200 px-2 py-3 rounded-lg shadow-sm w-full bg-white"
        value={routingNumber}
        onChange={event => setRoutingNumber(event.target.value)}
        placeholder="Routing Number"
      />

      <div className="text-base">Confirm Routing Number</div>
      <Input
        className={`border-solid border-neutral-200 px-2 py-3 rounded-lg shadow-sm w-full bg-white ${
          routingNumbersMatch() ? '' : 'border-error'
        }`}
        value={confirmRoutingNumber}
        onChange={event => setConfirmRoutingNumber(event.target.value)}
        placeholder="Confirm Routing Number"
      />
      {!routingNumbersMatch() && <div className="text-error">Routing numbers do not match</div>}

      <div className="text-base">Country</div>
      <Select
        className="border-solid border-neutral-200 rounded-lg shadow-sm w-full bg-white"
        value={country}
        showSearch
        labelInValue
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={setCountry}
        placeholder="Select Country"
      >
        {countryOptions}
      </Select>

      <div className="text-base">Is this an individual or company account?</div>
      <Radio.Group
        className="flex flex-row gap-4"
        value={type}
        onChange={event => setType(event.target.value)}
      >
        <Radio value="individual">Individual</Radio>
        <Radio value="company">Company</Radio>
      </Radio.Group>

      <div className="flex justify-end w-full">
        <Button className="mt-4" onClick={handleSubmit} loading={loading} type="primary">
          Add Bank Account
        </Button>
      </div>
    </div>
  );
}
