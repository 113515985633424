import React from 'react';
import Card from '../Card';

export default function SettingsCard({ name, children }) {
  return (
    <div>
      <div
        style={{
          fontSize: 16,
          fontWeight: 500,
          paddingBottom: 15,
          color: '#4e4e4e',
          fontFamily: 'Brown',
          textAlign: 'left',
        }}
      >
        {name}
      </div>

      <Card>
        <div style={{ textAlign: 'left' }}>{children}</div>
      </Card>
    </div>
  );
}
