import React, { useState } from 'react';
import achImage from '../../images/ach_image.png';
import ccImage from '../../images/cc_image.png';
import { BiChevronRight } from 'react-icons/bi';
import AddACHScreen from './AddACHScreen';
import AddPaymentDialogContent from '../AppDialog/AddPaymentDialogContent';

export default function Onboarding({ user }) {
  const [screen, setScreen] = useState('payment-methods');

  return (
    <div className="App">
      <div className="dashPage">
        <AddPaymentDialogContent
          currentUser={user.data}
          userId={user.ids.pay}
          visible
          callback={() => {}}
          refreshUser={() => {}}
          onboarding
        />

        {screen === 'add-ach' && (
          <>
            <AddACHScreen currentUser={user.data} />
          </>
        )}
      </div>
    </div>
  );
}

export function PaymentMethodOptionCard({ icon, name, description, action }) {
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      action();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={action}
      onKeyDown={handleKeyDown}
      className="bg-white rounded-lg shadow-sm p-4 flex flex-row gap-5 w-[480px] items-center space-between justify-between cursor-pointer hover:border-blue-400 border-solid border-neutral-200"
    >
      <div className="flex flex-row gap-3 items-center">
        <div>{icon}</div>

        <div>
          <div className="text-lg font-semibold">{name}</div>
          <div>{description}</div>
        </div>
      </div>
      <BiChevronRight className="text-neutral-500 w-8 h-8" />
    </div>
  );
}
