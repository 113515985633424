import React, { useState, useEffect } from 'react';
import { Modal, message } from 'antd';
import ErrorBoundary from './components/ErrorBoundary';
import AppDialog from './components/AppDialog';
import TopBar from './components/TopBar';
import Account from './components/Account';
import Eula, { eulaLastUpdate } from './components/Eula';
import Loading from './components/Loading';
import FeatureToggle, { On, Off, featureTypes } from './components/FeatureToggle';
import './styles/App.css';
import Onboarding from './components/Onboarding';

/**
 * Mostly temporary and for testing
 * This component has the logic to check the Auth0 session
 * And see if the user is logged in
 */
function App({ auth, history, location }) {
  const [loading, setLoading] = useState(!auth.currentUser);
  const [loggedIn, setLoggedIn] = useState(auth.currentUser != null);
  const [user, setUser] = useState(auth.currentUser);
  const [appDialogVisible, setAppDialogVisible] = useState(false);
  const [showEulaPage, setShowEulaPage] = useState(false);
  const [showOnboardingPage, setShowOnboardingPage] = useState(false);
  const [signup, setSignup] = useState(false);

  auth.onUserUpdate(currentUser => {
    if (currentUser) {
      setLoggedIn(true);
      setLoading(false);
      setUser(currentUser);
    } else if (user == null) {
      // We only want to do this if user is also null because
      // Auth0 will redirect after logout, so don't want to update state
      setLoggedIn(false);
      setLoading(false);
    }
  });

  useEffect(() => {
    if (!loading && user && window.location.pathname.includes('eula')) {
      setShowEulaPage(true);
      if (window.location.search.includes('signup')) {
        setSignup(true);
      }
    }

    if (!loading && user && window.location.pathname.includes('onboarding')) {
      setShowOnboardingPage(true);
      if (window.location.search.includes('signup')) {
        setSignup(true);
      }
    }
  }, [user, loading]);

  if (!loading && !loggedIn) {
    window.location = '/login';
  }

  return (
    <ErrorBoundary user={user}>
      {loading && <Loading />}
      {!loading && loggedIn && user && (
        <>
          <TopBar currentUser={user.data} />

          {!showEulaPage &&
            !(
              user.data.eula &&
              user.data.eula.signed &&
              new Date(user.data.eula.timestamp) > eulaLastUpdate
            ) && (
              <FeatureToggle feature={featureTypes.EULA}>
                <On>
                  <Modal
                    title="Sorry for the Interruption"
                    visible
                    closable={false}
                    onOk={() => {
                      setShowEulaPage(true);
                      history.push('/eula');
                    }}
                    onCancel={() =>
                      message.error(
                        'Sorry, you must accept the End User License Agreement before continuing'
                      )
                    }
                  >
                    <p>
                      {`We recently updated our End User License Agreement. Please read and accept the
                    agreement before continuing`}
                    </p>
                  </Modal>
                </On>
                <Off />
              </FeatureToggle>
            )}

          {showEulaPage && (
            <Eula
              user={user}
              history={history}
              refreshUser={() => auth.refreshUser(user.data.email)}
              hidePage={() => setShowEulaPage(false)}
              signup={signup}
            />
          )}

          {!showEulaPage && !showOnboardingPage && (
            <>
              <AppDialog
                user={user}
                visible={appDialogVisible}
                hideAppDialog={() => setAppDialogVisible(false)}
                refreshUser={() => auth.refreshUser(user.data.email)}
                callback={() => setAppDialogVisible(false)}
              />
              <Account
                user={user}
                logoutUser={auth.logout}
                auth={auth}
                location={location}
                showAddPayment={() => setAppDialogVisible(true)}
                refreshUser={() => auth.refreshUser(user.data.email)}
              />
            </>
          )}

          {!showEulaPage && showOnboardingPage && <Onboarding user={user} />}
        </>
      )}
    </ErrorBoundary>
  );
}

export default App;
