import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import App from './App';
import Auth from './Auth';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Loading from './components/Loading';

const searchParams = new URLSearchParams(window.location.search);
const appRef = searchParams.get('ref');
const appPath = searchParams.get('path');
const appQuery = searchParams.get('query');
const auth = new Auth(appRef, appPath, appQuery);

export default () => {
  return (
    <BrowserRouter>
      <>
        <Switch>
          <Route
            exact
            path="/"
            render={props => <App auth={auth} history={props.history} location={props.location} />}
          />
          <Route
            path="/eula"
            render={props => <App auth={auth} history={props.history} location={props.location} />}
          />
          <Route
            path="/onboarding"
            render={props => <App auth={auth} history={props.history} location={props.location} />}
          />
          <Route path="/login" render={props => <Login auth={auth} history={props.history} />} />
          <Route path="/signup" render={props => <SignUp auth={auth} history={props.history} />} />
          <Route
            path="/register"
            render={props => {
              const inviteCode = window.location.pathname.replace('/register/', '');
              return <SignUp auth={auth} history={props.history} inviteCode={inviteCode} />;
            }}
          />
          <Route
            path="/callback"
            render={props => {
              let authCompleteUrl = '';
              if (appPath) {
                authCompleteUrl = authCompleteUrl.concat(appPath);
              }
              if (appQuery || appRef) {
                if (window.location.href.includes('?')) {
                  authCompleteUrl += '&';
                } else {
                  authCompleteUrl += '?';
                }
              }
              if (appRef) {
                authCompleteUrl = authCompleteUrl.concat(`ref=${appRef}`);
              }
              if (appQuery) {
                authCompleteUrl = authCompleteUrl.concat(`&query=${appQuery}`);
              }

              auth
                .handleAuthentication(props)
                .then(() => props.history.push(authCompleteUrl))
                .catch(error => {
                  console.error(error.message);
                  props.history.push('/');
                });
              return <Loading />;
            }}
          />
          <Route
            path="/logout"
            render={() => {
              auth.logout();
              return <Loading />;
            }}
          />
        </Switch>
      </>
    </BrowserRouter>
  );
};
