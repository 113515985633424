import React from 'react';
import SettingsCard from './SettingsCard';
import aloaLogo from '../../images/logo.png';
import { currencyFormat } from '../../utils';

export default function AloaCreditsCard({ currentUser }) {
  const creditAmonut = currentUser.credits ? currentUser.credits : 0;

  return (
    <SettingsCard name="Aloa Credits">
      <div className="payment-card">
        <img className="cc-img" alt="" src={aloaLogo} />
        <div style={{ display: 'inline-block' }}>{currencyFormat(creditAmonut)}</div>
      </div>
      <div style={{ marginTop: '8px' }}>
        You can use these credits when paying for a project milestone
      </div>
      <div style={{ clear: 'right' }} />
    </SettingsCard>
  );
}
