/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import aloaSmallLine from '../../images/aloa_small_line.png';
import './SignUp.css';

export default function Redirect({ logoutUser, history }) {
  return (
    <div style={{ textAlign: 'center' }}>
      <div className="redirect-section">
        <img className="logo-image" src={aloaSmallLine} alt="" />
        <p>Looks like you're logged in...</p>
        <p>You'll need to log out before you can use the invite link.</p>
        <br />
        <p className="redirect-action-text" onClick={logoutUser} onKeyPress={history.goBack}>
          Click here to log out
        </p>
        <p>or</p>
        <p className="redirect-action-text" onClick={history.goBack} onKeyPress={history.goBack}>
          Click here to go back
        </p>
      </div>
    </div>
  );
}
