import firebase from 'firebase';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyCtPaIu4p_PiquqG1YLiWQUi-YiH50rDiU',
  authDomain: 'aloa-devpay.firebaseapp.com',
  databaseURL: 'https://aloa-devpay.firebaseio.com',
  projectId: 'aloa-devpay',
  storageBucket: 'aloa-devpay.appspot.com',
  messagingSenderId: '435748674035',
};
firebase.initializeApp(config);

firebase
  .firestore()
  .enablePersistence({ experimentalTabSynchronization: true })
  .then(() => {
    // Initialize Cloud Firestore through firebase
  })
  .catch(err => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      console.log('error, multiple tabs open!');
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
      console.log("error, current browser doesn't support");
    }
  });

export const db = firebase.firestore();
export const auth = firebase.auth();
export default firebase;
