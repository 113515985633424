/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { Button, Form, message } from 'antd';

function CreditCardForm({ user, ccReceived, children }) {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async ev => {
    ev.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { token, error } = await stripe.createToken(cardNumberElement, { name: user.name });

    if (error) {
      console.log('[error]', error);

      message.error(error.message);
      setLoading(false);
    } else {
      ccReceived(token)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  };

  return (
    <div className="flex flex-col gap-4 items-start mt-4 max-w-lg">
      <div className="flex flex-col gap-2 items-start w-full">
        <div className="text-base">Card number</div>
        <CardNumberElement className="border-solid border-neutral-200 px-2 py-3 rounded-lg shadow-sm w-full bg-white" />
      </div>

      <div className="flex flex-row gap-10 items-start justify-start w-full">
        <div className="flex flex-col gap-2 items-start">
          <div className="text-base">Expiration Date</div>
          <CardExpiryElement className="border-solid border-neutral-200 px-2 py-3 rounded-lg shadow-sm w-52 bg-white" />
        </div>

        <div className="flex flex-col gap-2 items-start">
          <div className="text-base">CVC</div>
          <CardCvcElement className="border-solid border-neutral-200 px-2 py-3 rounded-lg shadow-sm w-52 bg-white" />
        </div>
      </div>
      <div className="flex flex-row justify-end w-full mt-5">
        {children}
        <button
          className="bg-blue-600 hover:bg-blue-800 font-medium text-sm text-white px-6 py-2 rounded-lg shadow-lg cursor-pointer border-none"
          onClick={e => {
            handleSubmit(e);
          }}
          disabled={loading}
          type="button"
        >
          Add card
        </button>
      </div>

      <div className="text-sm text-neutral-400 w-full text-center mt-3">
        Aloa uses Stripe as our secure payment processor. Your card information is never stored on
        our servers.
      </div>
    </div>
  );
}

export default CreditCardForm;
