import React, { useState } from 'react';
import { Button, message, Input, Select } from 'antd';

export default function VerifyForm({ submit }) {
  const [charge1, setCharge1] = useState('');
  const [charge2, setCharge2] = useState('');

  const handleCharge1Change = event => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      if (event.target.value.length <= 2) {
        setCharge1(event.target.value);
      }
    }
  };

  const handleCharge2Change = event => {
    const re = /^[0-9\b]+$/;

    if (event.target.value === '' || re.test(event.target.value)) {
      if (event.target.value.length <= 2) {
        setCharge2(event.target.value);
      }
    }
  };

  const handleSubmit = () => {
    if (charge1 === '') {
      message.error('Charge 1 Required');
    } else if (charge2 === '') {
      message.error('Charge 2 Required');
    } else {
      submit([parseInt(charge1, 10), parseInt(charge2, 10)]);
    }
  };

  return (
    <div>
      <div
        style={{
          padding: '15px',
          backgroundColor: '#fff6e7',
          borderRadius: '8px',
          marginBottom: '15px',
        }}
      >
        We sent two small deposits to this bank account. To verify this account, please confirm the
        amounts of these deposits.
      </div>
      <div
        style={{
          color: '#5A7685',
          lineHeight: '30px',
          fontSize: '18px',
          fontFamily: 'Sofia Pro',
          fontWeight: 200,
        }}
      >
        First Deposit
        <br />
        <Input
          style={{ width: '150px' }}
          value={charge1}
          onChange={handleCharge1Change}
          addonBefore="$0."
          placeholder="52"
        />
        <div style={{ marginBottom: '15px' }} />
        Second Deposit
        <br />
        <Input
          style={{ width: '150px' }}
          value={charge2}
          onChange={handleCharge2Change}
          addonBefore="$0."
          placeholder="48"
        />
        <br />
        <br />
      </div>

      <div style={{ marginRight: '30px' }}>
        <Button type="primary" onClick={handleSubmit}>
          Verify Account
        </Button>
      </div>
    </div>
  );
}
