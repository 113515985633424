import React, { useState, useEffect } from 'react';
import { message, Modal } from 'antd';
import { TbTrash } from 'react-icons/tb';
import VerifyForm from './VerifyForm';
import { deletePaymentMethod, verifySource } from '../../api/users';
import ccImg from '../../images/cc.svg';
import bankImg from '../../images/bank.svg';

const { confirm } = Modal;

export default function PaymentCard({ currentUser, id, method, editPaymentMethods, refreshUser }) {
  const [showDelete, setShowDelete] = useState(editPaymentMethods);

  useEffect(() => {
    if (editPaymentMethods !== showDelete) {
      setShowDelete(editPaymentMethods);
    }
  }, [editPaymentMethods]);

  const showConfirm = paymentMethod => {
    const brand = paymentMethod.type === 'ach' ? paymentMethod.bank_inst : paymentMethod.brand;
    confirm({
      title: `Do you want to delete this payment method?`,
      content: `${brand} •••• ${paymentMethod.last4}`,
      onOk() {
        deletePaymentMethod(currentUser, id, paymentMethod)
          .then(() => {
            refreshUser();
            message.success('Deleted payment method');
          })
          .catch(error => message.error(error.message));
      },
      onCancel() {},
    });
  };

  return (
    <div
      className="w-full flex flex-col gap-2 items-start  p-6 border-solid border-neutral-200 rounded-lg"
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false || editPaymentMethods)}
    >
      <div className="flex flex-row items-center gap-2 justify-between w-full">
        <div className="flex flex-row items-center gap-2 w-full">
          {(method.type === 'card' || method.type === 'sepa_debit') && (
            <img className="cc-img" alt="" src={ccImg} />
          )}

          {method.type === 'ach' && <img className="bank-img" alt="" src={bankImg} />}

          {(method.type === 'card' || method.type === 'sepa_debit') && (
            <div className="card-method">
              {method.brand}
              {` ••••`}
              {method.last4}
            </div>
          )}

          {method.type === 'ach' && (
            <div className="ach">
              {method.bank_inst}
              {` ••••`}
              {method.last4}
            </div>
          )}
        </div>

        <TbTrash
          className="h-4 w-4 text-gray-400 cursor-pointer hover:text-gray-800"
          onClick={() => {
            showConfirm(method);
          }}
        />
      </div>

      {method.type === 'ach' && method.verified === false && (
        <>
          <div className="ach-pending-verification" cursor="pointer">
            Pending Verification
          </div>

          <VerifyForm
            submit={charges => {
              verifySource(currentUser, id, method.source_id, charges)
                .then(result => {
                  if (result.type === 'StripeInvalidRequestError') {
                    console.log(result);
                    message.error(result.message);
                  } else {
                    refreshUser();
                    message.success('Bank successfully verified!');
                  }
                })
                .catch(error => {
                  console.log(error);

                  message.error(error.code);
                });
            }}
          />
        </>
      )}
    </div>
  );
}
