/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, message, Input } from 'antd';
import PasswordResetDialog from './PasswordResetDialog';
import { setResetEmailSent } from '../../api/users';

// images
import aloaSmallLine from '../../images/aloa_small_line.png';
import styles from './styles';
import './Login.css';

// This is a way in the future we can control features from firebase
// Set this value from firebase will allow anyone to signup (without invite link)
const signupEnabled = true;

export default function Login({ auth, history }) {
  const [tempEmail, setTempEmail] = useState('');
  const [tempResetEmail, setTempResetEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [forgotPassState, setForgotPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailAlreadySent, setEmailAlreadySent] = useState(false);
  const [passwordResetDialogVisible, setPasswordResetDialogVisible] = useState(false);

  if (auth.currentUser) {
    history.push('/');
  }

  auth.onUserUpdate(user => {
    if (user) {
      history.push('/');
    }
  });

  const handleClick = () => {
    if (!tempEmail) {
      message.error('Please enter your email to login');
    } else if (!tempPassword) {
      message.error('Password is required for login');
    } else {
      setLoading(true);
      auth
        .login(tempEmail, tempPassword)
        .then(result => {
          setLoading(false);
          if (!result.message) {
            message.success('User logged in!');
          } else if (
            !result.resetEmailSent &&
            result.promptForPasswordUpdate &&
            !result.resetEmailAlreadySent
          ) {
            setPasswordResetDialogVisible(true);
          } else if (
            !result.resetEmailSent &&
            result.promptForPasswordUpdate &&
            result.resetEmailAlreadySent
          ) {
            setEmailAlreadySent(true);
            setPasswordResetDialogVisible(true);
          } else {
            message.error(result.message);
          }
        })
        .catch(error => {
          setLoading(false);
          message.error(error.message);
        });
    }
  };

  const forgotPass = () => {
    setForgotPassState(!forgotPassState);
  };

  const enterPressed = event => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      handleClick();
    }
  };

  const handleChange = (event, type) => {
    if (type === 'email') {
      setTempEmail(event.target.value);
    }

    if (type === 'reset_email') {
      setTempResetEmail(event.target.value);
    }

    if (type === 'password') {
      setTempPassword(event.target.value);
    }
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <PasswordResetDialog
        visible={passwordResetDialogVisible}
        setVisible={setPasswordResetDialogVisible}
        email={tempEmail}
        emailAlreadySent={emailAlreadySent}
        sendResetPasswordEmail={() => auth.sendResetPasswordEmail(tempEmail)}
        setResetEmailSent={() => setResetEmailSent(tempEmail)}
      />
      <div className="box">
        <div className="bg-white shadow-md rounded-lg py-12 px-10 w-96 flex flex-col items-center">
          <img
            src={aloaSmallLine}
            style={{
              height: '45px',
              paddingTop: 10,
              display: 'block',
            }}
            className="mb-10"
          />

          {!forgotPassState && (
            <div className="flex flex-col w-full">
              <Input
                label="Email"
                placeholder="example@gmail.com"
                style={{ width: '100%' }}
                margin="normal"
                onChange={e => handleChange(e, 'email')}
                value={tempEmail}
                onKeyPress={enterPressed}
              />
              <br />

              <Input
                label="Password"
                placeholder="Not password123"
                style={{ width: '100%' }}
                margin="normal"
                type="password"
                onChange={e => handleChange(e, 'password')}
                value={tempPassword}
                onKeyPress={enterPressed}
              />
              <br />
              <Button
                variant="contained"
                type="submit"
                primary="true"
                style={{
                  backgroundColor: '#0080fd',
                  color: '#ffffff',
                  width: '100%',
                  marginTop: '20px',
                }}
                loading={loading}
                onClick={handleClick}
              >
                Login
              </Button>

              <div
                style={{
                  paddingTop: '1.5em',
                  textAlign: 'center',
                }}
              >
                <a
                  onClick={forgotPass}
                  style={{
                    color: '#000000',
                    cursor: 'pointer',
                  }}
                >
                  Forgot Password?
                </a>
              </div>
            </div>
          )}

          {forgotPassState && (
            <div style={{ marginTop: 0 }}>
              <div style={{ marginTop: 20 }} className="mb-6 items-center text-center">
                {!emailSent
                  ? "No worries, we'll send you a link to reset it."
                  : 'Email sent - Check your inbox for the link to reset your password'}
              </div>

              <Input
                label="Email"
                placeholder="example@gmail.com"
                style={{ width: '100%' }}
                margin="normal"
                onChange={e => handleChange(e, 'reset_email')}
                value={tempResetEmail}
                disabled={emailSent}
              />
              <br />

              <Button
                variant="contained"
                type="submit"
                primary
                style={{
                  backgroundColor: '#0080fd',
                  color: '#ffffff',
                  width: '100%',
                  marginTop: '20px',
                }}
                onClick={() => {
                  if (!emailSent) {
                    auth
                      .sendResetPasswordEmail(tempResetEmail)
                      .then(() => setEmailSent(true))
                      .catch(error => message.error(error.message));
                  } else {
                    setForgotPassState(false);
                    setEmailSent(false);
                  }
                }}
              >
                {!emailSent ? 'Reset Password' : 'Back to Login'}
              </Button>

              {!emailSent && (
                <div
                  style={{
                    paddingTop: '1.5em',
                    textAlign: 'center',
                  }}
                >
                  <a
                    onClick={forgotPass}
                    style={{
                      color: '#000000',
                      cursor: 'pointer',
                    }}
                  >
                    Nevermind, I remember
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {signupEnabled && (
        <div className="flex flex-row gap-2 items-center">
          {`Don't have an account?`}
          <Link to="/signup"> Sign Up </Link>
        </div>
      )}
    </div>
  );
}
