import React, { useState, useEffect } from 'react';
import { PiBankDuotone } from 'react-icons/pi';
import { TbCircleChevronLeft } from 'react-icons/tb';
import { usePlaidLink } from 'react-plaid-link';
import { Button, Spin, message } from 'antd';
import { keys, env } from '../../../../constants';

export default function AddBankAccount({
  currentUser,
  handleAddPlaidSource,
  cancelActive,
  addNonPlaidBank,
}) {
  const { plaidPublicKey } = keys;
  const { plaidEnvironment } = env;
  const [loading, setLoading] = useState(false);
  const [generatedToken, setGeneratedToken] = useState(null);

  useEffect(() => {
    fetchLinkToken(currentUser).then(token => {
      setGeneratedToken(token);
      setLoading(false);
    });
  }, [loading, currentUser]);

  const fetchLinkToken = async user => {
    console.log('fetching link token', user);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/${
        process.env.REACT_APP_API_PATH
      }/plaid/createLinkToken`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: {
            id: user.id,
          },
          redirect_uri: `${window.location.protocol}//${window.location.host}/plaid-oauth`,
        }),
      }
    );

    console.log('response', response);

    const responseJSON = await response.json();

    console.log('responseJSON', responseJSON);
    return responseJSON.link_token;
  };

  const { open, ready } = usePlaidLink({
    token: generatedToken,
    onSuccess: (token, metadata) => {
      // send public_token to server
      handleAddPlaidSource(token, metadata)
        .then(() => {
          message.success('Added your bank account!');
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="font-['Avenir_Next']">
      <div className="flex flex-row gap-2 items-center justify-start w-full">
        <div
          onClick={cancelActive}
          onKeyPress={cancelActive}
          role="button"
          tabIndex={0}
          className="flex flex-row gap-2 items-center cursor-pointer text-neutral-500 hover:text-purple-800 text-[16px]"
        >
          <TbCircleChevronLeft />
          <div>Back</div>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-center pt-8">
        <div className="text-xl font-medium">🏦 Lets link your bank</div>
      </div>
      <div className="flex flex-col gap-8 items-start pt-8">
        <div className="text-lg">
          First, let&apos;s see if your bank supports instant verification with Plaid (our secure
          bank verification partner). Click the button below to get started 👇
        </div>
        <Spin spinning={loading}>
          <button
            className="bg-blue-600 hover:bg-blue-800 font-medium text-base text-white px-8 py-4 rounded-lg shadow-lg cursor-pointer border-none"
            onClick={() => open()}
            disabled={!ready}
            type="button"
          >
            Select Bank
          </button>
        </Spin>

        <div className="text-lg">
          Didn&apos;t see your bank? No worries, you can still add your bank account manually. Click
          below 👇
        </div>

        <button
          onClick={() => {
            addNonPlaidBank();
          }}
          type="button"
          className="bg-white-600 border-1 border-solid border-neutral-300 font-medium text-base text-neutral-700 px-10 py-4 rounded-lg shadow-lg bg-white cursor-pointer hover:shadow-xl"
        >
          I didn&apos;t see my bank
        </button>
      </div>
    </div>
  );
}
