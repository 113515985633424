/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
// File to interface with Aloa API and modify users in firebase
import stripeApi from './stripe';
import { db } from '../firebase';

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const apiPath = process.env.REACT_APP_API_PATH;

/**
 * Function to fetch the user data related to a specified email
 * @param {String} email user email
 */
export const getUserForEmail = email =>
  new Promise((resolve, reject) => {
    const url = `${apiUrl}/${apiPath}/auth/u/email/${email}`;
    fetch(url)
      .then(async response => resolve(await response.json()))
      .catch(error => reject(error));
  });

/**
 * Update user for the provided ids
 * @param {Object} user Updated user data (will not delete fields)
 * @param {Object} ids Ids keyed by apps to update
 */
export const updateUser = (user, ids) =>
  new Promise((resolve, reject) => {
    let url = `${apiUrl}/${apiPath}/auth/u/?`;

    Object.keys(ids).forEach(key => {
      url = url.concat(`${key}=${ids[key]}&`);
    });

    fetch(url, {
      method: 'PUT',
      body: JSON.stringify(user),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => resolve('Account updated'))
      .catch(error => reject(error));
  });

/**
 * Function to create user in server, in all applications
 * @param {String} image Url of uploaded image for new user
 * @param {String} name Name of new user
 * @param {String} company Company of new user
 * @param {String} email Email of new user
 * @param {String} password Password of new user
 */
export const createUser = user =>
  new Promise((resolve, reject) => {
    const url = `${apiUrl}/${apiPath}/auth/u/create`;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(user),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.json())
      .then(responseJson => {
        if (!responseJson.error) {
          resolve(responseJson.data);
        } else {
          reject(responseJson.error);
        }
      })
      .catch(error => reject(error));
  });

/**
 * Update user password in Auth0
 * @param {String} userId Id of user to update in Auth0
 * @param {String} newPassword new password to set for user
 */
export const updateUserPassword = (userId, password, confirmPassword) =>
  new Promise((resolve, reject) => {
    if (password !== confirmPassword) {
      reject(Error('Passwords do not match'));
    } else {
      const url = `${apiUrl}/${apiPath}/auth/u/${userId}/updatePassword`;
      const requestBody = {
        password,
        verify_password: confirmPassword,
      };

      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(requestBody),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(async response => {
          const responseJson = await response.json();
          if (response.status > 200) {
            if (responseJson.message.includes('PasswordStrengthError')) {
              reject(Error('Error: Password is too weak'));
            } else {
              reject(Error(responseJson.message));
            }
          } else {
            resolve(response);
          }
        })
        .catch(error => reject(error));
    }
  });

/**
 * Adds plaid source to customer
 * @param {Object} currentUser User to add source to
 * @param {String} userId User id in Aloa Pay
 * @param {String} token - String token for Plaid to parse
 * @param {Object} metadata - Object with account data
 * @returns {Promise}
 */
export const addPlaidSource = (currentUser, userId, token, metadata) =>
  new Promise((resolve, reject) => {
    console.log('addPlaidSource', currentUser, userId, token, metadata);
    const customerId =
      process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
        ? currentUser.customer_id_dev
        : currentUser.customer_id;

    stripeApi
      .addPlaidSource(customerId, metadata.account_id, token)
      .then(response => {
        const paymentMethod = {
          token_id: metadata.account.id,
          last4: metadata.account.mask,
          type: 'ach',
          brand: metadata.account.name,
          bank_inst: metadata.institution.name,
          source_id: response.id,
          verified: true,
        };

        console.log('addPlaidSource', paymentMethod);
        console.log('userId', userId);
        addPaymentMethod(userId, paymentMethod)
          .then(() => resolve(response))
          .catch(error => reject(error));
      })
      .catch(error => reject(error));
  });

/**
 * Adds bank data to user
 * @param {Object} currentUser
 * @param {String} userId User id in Aloa Pay
 * @param {Object} bankData
 * @returns {Promise}
 */
export const addBankAccount = (currentUser, userId, bankData) =>
  new Promise((resolve, reject) => {
    const customerId =
      process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
        ? currentUser.customer_id_dev
        : currentUser.customer_id;

    const bankAccount = {
      account_holder_name: bankData.accountName,
      account_holder_type: bankData.type,
      object: 'bank_account',
      country: bankData.country,
      account_number: bankData.accountNumber,
      routing_number: bankData.routingNumber,
    };

    stripeApi
      .addSource(customerId, bankAccount)
      .then(response => {
        if (response.type === 'StripeInvalidRequestError') {
          reject(Error(response.message));
        } else {
          const paymentMethod = {
            last4: response.last4,
            type: 'ach',
            brand: response.object,
            bank_inst: response.bank_name,
            source_id: response.id,
            verified: false,
          };

          addPaymentMethod(userId, paymentMethod)
            .then(() => resolve(response))
            .catch(error => reject(error));
        }
      })
      .catch(error => reject(error));
  });

/**
 * Verifies bank for user
 * @param {Object} currentUser User verifying source
 * @param {String} userId User id to update
 * @param {String} sourceId Source to be verified
 * @param {[Int]} amounts Array of amounts from verify charges
 */
export const verifySource = (currentUser, userId, sourceId, amounts) =>
  new Promise((resolve, reject) => {
    const customerId =
      process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
        ? currentUser.customer_id_dev
        : currentUser.customer_id;
    stripeApi
      .verifySource(customerId, sourceId, amounts)
      .then(response => {
        const { payment_methods } = currentUser;
        payment_methods.forEach(paymentMethod => {
          if (paymentMethod.source_id === sourceId) {
            paymentMethod.verified = true;
          }
        });
        currentUser.payment_methods = payment_methods;
        db.collection('users')
          .doc(userId)
          .set({ payment_methods }, { merge: true })
          .then(() => resolve(response))
          .catch(error => reject(error));
      })
      .catch(error => reject(error));
  });

/**
 * Adds credit card to user
 * @param {Object} currentUser User adding credit card
 * @param {String} userId User id to update
 * @param {object} token - Token with credit card data
 * @returns {Promise}
 */
export const addCreditCard = (currentUser, userId, token) =>
  new Promise((resolve, reject) => {
    const customerId =
      process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
        ? currentUser.customer_id_dev
        : currentUser.customer_id;

    stripeApi
      .addSource(customerId, token.id)
      .then(response => {
        if (response.type === 'StripeInvalidRequestError') {
          reject(Error(response.message));
        } else {
          const payment_method = {
            token_id: token.id,
            source_id: response.id,
            last4: token.card.last4,
            type: token.type,
            brand: token.card.brand,
            verified: true,
          };
          addPaymentMethod(userId, payment_method)
            .then(() => resolve(response))
            .catch(error => reject(error));
        }
      })
      .catch(error => reject(error));
  });

/**
 * Utility function with common logic to add payment methods to user
 * @param {String} userId User id to update
 * @param {Object} payment_method
 * @returns {Promise}
 */
const addPaymentMethod = async (userId, payment_method) => {
  try {
    const userRef = db.collection('users').doc(userId);
    const doc = await userRef.get();

    if (!doc.exists) {
      throw new Error("Error: can't find user");
    }

    const userPaymentMethods = doc.get('payment_methods') || [];
    userPaymentMethods.push(payment_method);

    await userRef.update({ payment_methods: userPaymentMethods });

    return 'Payment method added successfully'; // This value will be wrapped in a resolved Promise
  } catch (error) {
    throw error;
  }
};

/**
 * Function to delete payment method for user
 * Updates the user in store when complete
 * @param {String} userId User id to update
 * @param {Object} payment_method
 * @returns {Promise}
 */
export const deletePaymentMethod = async (currentUser, userId, payment_method) => {
  try {
    const customerId =
      process.env.REACT_APP_ENV === 'dev' && currentUser.customer_id_dev
        ? currentUser.customer_id_dev
        : currentUser.customer_id;

    const userRef = db.collection('users').doc(userId);
    const doc = await userRef.get();

    if (!doc.exists) {
      throw new Error("Error: Can't find user");
    }

    const paymentMethods = doc.get('payment_methods');
    const updatedPaymentMethods = paymentMethods.filter(
      paymentMethod => paymentMethod.source_id !== payment_method.source_id
    );

    await userRef.update({ payment_methods: updatedPaymentMethods });
    await stripeApi.removeSource(customerId, payment_method.source_id);

    return 'Payment method deleted successfully'; // This value will be wrapped in a resolved Promise
  } catch (error) {
    throw error;
  }
};

/**
 * Function to fetch the invite data related to a code
 * @param {String} code invite code
 */
export const getInviteForCode = code =>
  new Promise((resolve, reject) => {
    const url = `${apiUrl}/${apiPath}/auth/u/invite/${code}`;
    fetch(url)
      .then(async response => resolve(await response.json()))
      .catch(error => reject(error));
  });

/**
 * Function to check if user needs to update/refresh their password
 * @param {string} email email
 */
export const passwordUpdateRequired = email =>
  new Promise((resolve, reject) => {
    const url = `${apiUrl}/${apiPath}/auth/u/passwordUpdateRequired/${email}`;
    fetch(url)
      .then(async response => resolve(await response.json()))
      .catch(error => reject(error));
  });

/**
 * Function to check if a reset password email was sent for the user (needed in migration)
 */
export const resetEmailSent = email =>
  new Promise((resolve, reject) => {
    const url = `${apiUrl}/${apiPath}/auth/u/resetEmailSent/${email}`;
    fetch(url)
      .then(async response => resolve(await response.json()))
      .catch(error => reject(error));
  });

/**
 * Function to check if a reset password email was sent for the user (needed in migration)
 */
export const setResetEmailSent = email =>
  new Promise((resolve, reject) => {
    const url = `${apiUrl}/${apiPath}/auth/u/resetEmailSent/${email}`;
    fetch(url, { method: 'POST' })
      .then(async response => resolve(await response.json()))
      .catch(error => reject(error));
  });
