import React from 'react';
import { Modal } from 'antd';
import AddPaymentDialogContent from './AddPaymentDialogContent';

/**
 * AppDialog to walk user through main app actions
 */
export default function AppDialog({ user, visible, title, callback, hideAppDialog, refreshUser }) {
  return (
    <Modal
      open={visible}
      footer={null}
      width="570px"
      style={{ padding: '5px', zIndex: 100 }}
      name={title}
      onCancel={hideAppDialog}
    >
      <div className="mx-[-24px] my-[-20px] bg-[#FAFAFA] rounded-lg p-8">
        <AddPaymentDialogContent
          currentUser={user.data}
          userId={user.ids.pay}
          visible={visible}
          callback={callback}
          refreshUser={refreshUser}
        />
      </div>
    </Modal>
  );
}
