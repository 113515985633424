import React, { useState } from 'react';
import { Button, message } from 'antd';
import SettingsCard from './SettingsCard';
import { updateUserPassword } from '../../api/users';
import './Account.css';

const newPasswordDefault = {
  pass1: '',
  pass2: '',
};

export default function ChangePasswordCard({ auth }) {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState(newPasswordDefault);

  const changePassword = () => {
    updateUserPassword(auth.userId, newPassword.pass1, newPassword.pass2)
      .then(() => {
        message.success('Password successfully changed!');
        setNewPassword(newPasswordDefault);
      })
      .catch(error => {
        message.error(error.message);
      })
      .finally(() => {
        setNewPassword({ pass1: '', pass2: '' });
      });
  };

  const handleChange = (event, type) => {
    // For passwords
    if (type === 'pass1') {
      newPassword.pass1 = event.target.value;
    }

    if (type === 'pass2') {
      newPassword.pass2 = event.target.value;
    }

    setNewPassword({ ...newPassword });
  };

  if (showChangePassword) {
    return (
      <SettingsCard name="Security">
        <div
          style={{
            fontSize: '17px',
            fontWeight: 500,
            fontFamily: 'Avenir Next',
            marginTop: '20px',
          }}
        >
          New Password
        </div>
        <input
          type="password"
          value={newPassword.pass1}
          className="line-text-box"
          onInput={e => handleChange(e, 'pass1')}
          onChange={() => {}}
        />

        <div
          style={{
            fontSize: '17px',
            fontWeight: 500,
            fontFamily: 'Avenir Next',
            marginTop: '20px',
          }}
        >
          Confirm Password
        </div>
        <input
          type="password"
          value={newPassword.pass2}
          className="line-text-box"
          onInput={e => handleChange(e, 'pass2')}
          onChange={() => {}}
        />

        <div className="account-action-button">
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => setShowChangePassword(!showChangePassword)}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={changePassword}>
            Change Password
          </Button>
        </div>

        <div style={{ clear: 'right' }} />
      </SettingsCard>
    );
  }
  return (
    <SettingsCard name="Security">
      <Button type="primary" onClick={() => setShowChangePassword(!showChangePassword)}>
        Change Password
      </Button>
    </SettingsCard>
  );
}
