import auth0 from 'auth0-js';
import { auth } from './firebase';
import { getUserForEmail, passwordUpdateRequired, resetEmailSent } from './api/users';

export default class Auth {
  auth0;
  accessToken;
  idToken;
  userId;
  expiresAt;
  currentUser;
  loading;
  onUserUpdateCallbacks = [];

  constructor(app, path, query) {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.checkSession = this.checkSession.bind(this);
    this.setSession = this.setSession.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.handleFirebaseToken = this.handleFirebaseToken.bind(this);
    this.onUserUpdate = this.onUserUpdate.bind(this);
    this.refreshUser = this.refreshUser.bind(this);

    switch (app) {
      case 'manage':
        this.callbackUri = `${process.env.REACT_APP_MANAGE_URL}/callback?`;
        break;
      case 'pay':
        this.callbackUri = `${process.env.REACT_APP_PAY_URL}/callback?`;
        break;
      case 'partners':
        this.callbackUri = `${process.env.REACT_APP_PARTNERS_URL}/callback?`;
        break;
      default:
        this.callbackUri = `${window.location.origin}/callback?`;
    }

    if (path) {
      this.callbackUri += `path=${path}&`;
    }

    if (query) {
      this.callbackUri += `query=${query}`;
    }

    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: this.callbackUri,
      responseType: 'token id_token',
      scope: 'openid profile email',
    });

    auth.onAuthStateChanged(async user => {
      if (user || !this.currentUser) {
        this.checkSession()
          .then(session => {
            const { email } = session.idTokenPayload;
            this.userId = session.idTokenPayload.sub;
            getUserForEmail(email).then(result => {
              this.currentUser = result;
              this.onUserUpdateCallbacks.forEach(callback => callback(this.currentUser));
            });
          })
          .catch(() => {
            this.onUserUpdateCallbacks.forEach(callback => callback(null));
          });
      } else {
        this.onUserUpdateCallbacks.forEach(callback => callback(null));
      }
    });
  }

  login(username, password, fromSignup, redirectToOnboarding) {
    if (fromSignup) {
      const path = redirectToOnboarding ? '/onboarding' : '/eula';
      this.auth0 = new auth0.WebAuth({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        redirectUri: `${
          window.location.origin
        }/callback?path=${path}?signup=true&ref=${process.env.REACT_APP_MANAGE_URL.replace(
          'http://',
          ''
        ).replace('https://', '')}`,
        responseType: 'token id_token',
        scope: 'openid profile email',
      });
    }

    return new Promise((resolve, reject) => {
      this.auth0.login(
        {
          realm: 'Username-Password-Authentication',
          username,
          password,
        },
        async (error, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            resolve(authResult);
          } else if (error) {
            if (username && password) {
              const promptForPasswordUpdate = await passwordUpdateRequired(username);
              const resetEmailAlreadySent = await resetEmailSent(username);
              if (promptForPasswordUpdate && !resetEmailAlreadySent) {
                resolve({
                  message: 'You need to update your password',
                  promptForPasswordUpdate,
                  resetEmailAlreadySent,
                });
              } else if (promptForPasswordUpdate && resetEmailAlreadySent) {
                resolve({
                  message: 'Issue reseting password',
                  promptForPasswordUpdate,
                  resetEmailAlreadySent,
                });
              } else {
                reject(Error(error.description));
              }
            } else {
              reject(Error(error.description));
            }
          }
        }
      );
    });
  }

  logout() {
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    this.auth0.logout({
      returnTo: `${process.env.REACT_APP_HOST}/login`,
    });

    auth
      .signOut()
      .then(() => {})
      .catch(error => console.error(error))
      .finally(() => {
        this.loading = false;
      });
  }

  checkSession() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          resolve(authResult);
        } else {
          reject(err);
        }
      });
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;
    this.handleFirebaseToken(this.idToken);
  }

  handleAuthentication({ history, location }) {
    return new Promise((resolve, reject) => {
      if (/access_token|id_token|error/.test(location.hash)) {
        this.auth0.parseHash(async (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            const result = await this.handleFirebaseToken(authResult.idToken);
            resolve(result);
          } else if (err) {
            console.error(err);
            reject(Error('Error parsing authentication credentials. Redirecting...'));
            setTimeout(() => history.push('/'), 1000);
          }
        });
      } else {
        reject(Error('Error parsing authentication credentials. Redirecting...'));
        setTimeout(() => history.push('/'), 1000);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleFirebaseToken(token) {
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged(user => {
        if (user) {
          resolve(user);
        }
      });

      fetch(process.env.REACT_APP_FIREBASE_TOKEN_API_ENDPOINT, {
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Origins': '*',
        },
      })
        .then(async response => {
          if (response.status < 400) {
            const responseJson = await response.json();
            auth.signInWithCustomToken(responseJson.token);
          } else {
            reject(Error('Error logging in. Please try again later.'));
          }
        })
        .catch(error => reject(error));
    });
  }

  onUserUpdate(callback) {
    this.onUserUpdateCallbacks.push(callback);
  }

  refreshUser(email) {
    if (email) {
      getUserForEmail(email).then(result => {
        this.currentUser = result;
        this.onUserUpdateCallbacks.forEach(callback => callback(this.currentUser));
      });
    }
  }

  sendResetPasswordEmail(email) {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        (err, resp) => {
          if (err) {
            reject(err);
          } else {
            resolve(resp);
          }
        }
      );
    });
  }
}
