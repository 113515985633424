/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import AccountInfoCard from './AccountInfoCard';
import BillingInfoCard from './BillingInfoCard';
import PaymentMethodCards from './PaymentMethodCards';
import ChangePasswordCard from './ChangePasswordCard';
import AloaCreditsCard from './AloaCreditCard';
import './Account.css';

export default function Account({
  user,
  logoutUser,
  showAddPayment,
  verifySource,
  location,
  refreshUser,
  auth,
}) {
  const currentUser = user.data;

  useEffect(() => {
    if (location.pathname.indexOf('verify') !== -1) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  });

  return (
    <div className="invoicePage">
      <AccountInfoCard currentUser={currentUser} ids={user.ids} />

      {currentUser.credits > 0 && <AloaCreditsCard currentUser={currentUser} />}

      {currentUser.acc_type !== 1 && (
        <PaymentMethodCards
          currentUser={currentUser}
          id={user.ids.pay}
          showAddPayment={showAddPayment}
          verifySource={verifySource}
          refreshUser={refreshUser}
        />
      )}

      <BillingInfoCard currentUser={currentUser} ids={user.ids} />

      <ChangePasswordCard auth={auth} />

      <a
        className="logout-button"
        onClick={logoutUser}
        onKeyPress={logoutUser}
        role="button"
        tabIndex={0}
      >
        Log Out
      </a>
    </div>
  );
}
