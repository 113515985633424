/* eslint-disable no-console */
import React, { useState } from 'react';
import { Button, message } from 'antd';
import { CloseOutline } from '@ant-design/icons';
import SettingsCard from './SettingsCard';
import { updateUser } from '../../api/users';

export default function BillingInfoCard({ currentUser, ids }) {
  const [changes, setChanges] = useState({ ...currentUser });
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      setOpen(false);
    }
  };

  const handleChange = (event, type) => {
    const billingInfo = { ...changes.billing_info };

    if (type === 'address_1') {
      billingInfo.address_1 = event.target.value;
    }

    if (type === 'address_2') {
      billingInfo.address_2 = event.target.value;
    }

    if (type === 'city') {
      billingInfo.city = event.target.value;
    }

    if (type === 'state') {
      billingInfo.state = event.target.value;
    }

    if (type === 'zip') {
      billingInfo.zip = event.target.value;
    }

    changes.billing_info = billingInfo;

    setChanges({ ...changes });
  };

  const saveBillingInfoChanges = () => {
    const changesToSave = {};
    if (changes.billing_info && JSON.stringify(changes) !== JSON.stringify(currentUser)) {
      changesToSave.billing_info = changes.billing_info;
    }

    if (Object.keys(changesToSave).length > 0 && ids.pay) {
      updateUser(changesToSave, { pay: ids.pay })
        .then(result => {
          message.success(result);
        })
        .catch(error => {
          message.error(error);
        });
    } else {
      message.info('All info up to date');
    }
  };

  if (!changes.billing_info) {
    changes.billing_info = {};
  }

  return (
    <>
      <SettingsCard name="Billing Info">
        <div
          style={{
            fontSize: '17px',
            fontWeight: 500,
            fontFamily: 'Avenir Next',
            marginTop: '20px',
          }}
        >
          Billing Address 1
        </div>
        <input
          value={changes.billing_info.address_1}
          className="line-text-box"
          onInput={e => handleChange(e, 'address_1')}
          onChange={() => {}}
        />

        <div
          style={{
            fontSize: '17px',
            fontWeight: 500,
            fontFamily: 'Avenir Next',
            marginTop: '20px',
          }}
        >
          Billing Address 2
        </div>
        <input
          value={changes.billing_info.address_2}
          className="line-text-box"
          onInput={e => handleChange(e, 'address_2')}
          onChange={() => {}}
        />

        <div
          style={{
            fontSize: '17px',
            fontWeight: 500,
            fontFamily: 'Avenir Next',
            marginTop: '20px',
          }}
        >
          City
        </div>
        <input
          value={changes.billing_info.city}
          className="line-text-box"
          onInput={e => handleChange(e, 'city')}
          onChange={() => {}}
        />

        <div
          style={{
            fontSize: '17px',
            fontWeight: 500,
            fontFamily: 'Avenir Next',
            marginTop: '20px',
          }}
        >
          State
        </div>
        <input
          value={changes.billing_info.state}
          className="line-text-box"
          onInput={e => handleChange(e, 'state')}
          onChange={() => {}}
        />

        <div
          style={{
            fontSize: '17px',
            fontWeight: 500,
            fontFamily: 'Avenir Next',
            marginTop: '20px',
          }}
        >
          Zip Code
        </div>
        <input
          value={changes.billing_info.zip}
          className="line-text-box"
          onInput={e => handleChange(e, 'zip')}
          onChange={() => {}}
        />

        <div className="account-action-button">
          <Button type="primary" onClick={saveBillingInfoChanges}>
            Save Billing Info
          </Button>
        </div>

        <div style={{ clear: 'right' }} />
      </SettingsCard>
    </>
  );
}
