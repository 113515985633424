import React from 'react';
import { message } from 'antd';
import ContentSection from './ContentSection';
import { updateUser } from '../../api/users';

export const eulaLastUpdate = new Date('September 19, 2019 12:00:00');

export default function Eula({ user, signup, history, refreshUser, hidePage }) {
  const signEula = () =>
    new Promise((resolve, reject) =>
      updateUser(
        {
          eula: {
            signed: true,
            timestamp: new Date(),
          },
        },
        user.ids
      )
        .then(() => {
          refreshUser();
          if (!signup) {
            message.success('Thanks! Now taking you back...');
          } else {
            message.success('Thanks! Now loading your dashboard...');
          }
          setTimeout(() => {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('ref')) {
              let windowRedirect = `http://${urlParams.get('ref')}`;

              if (urlParams.has('query')) {
                windowRedirect = windowRedirect.concat(`?${urlParams.get('query')}`);
              }

              window.location.replace(windowRedirect);
            } else {
              history.push('/');
            }

            hidePage();
            resolve();
          }, 2000);
        })
        .catch(error => {
          reject(error);
          console.error(error.message);
          message.error('Sorry, there was an error saving your response. Please try again later.');
        })
    );

  return (
    <div className="App">
      <div className="dashPage">
        <div
          style={{
            fontSize: 20,
            color: '#4e4e4e',
            fontFamily: 'Brown',
            fontWeight: 500,
            textAlign: 'left',
            marginBottom: '20px',
          }}
        >
          End User License Agreement
        </div>
        <ContentSection
          currentUser={user.data}
          signup={signup}
          sign={signEula}
          history={history}
          hidePage={hidePage}
        />
      </div>
    </div>
  );
}
