import React, { useState, useEffect } from 'react';
import FeatureContext from './contexts/FeatureContext';

function AppContextProvider({ children }) {
  const [features, setFeatures] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_API_PATH}/features`)
      .then(res => res.json())
      .then(resJson => {
        setFeatures(resJson);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return <FeatureContext.Provider value={features}>{children}</FeatureContext.Provider>;
}

export default AppContextProvider;
