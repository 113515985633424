import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';

export default function PasswordResetDialog({
  visible,
  setVisible,
  email,
  emailAlreadySent,
  sendResetPasswordEmail,
  setResetEmailSent,
}) {
  const [emailSent, setEmailSent] = useState(false);

  const emailResetButton = (
    <Button
      type="primary"
      onClick={() => {
        sendResetPasswordEmail()
          .then(() => {
            setEmailSent(true);
            setResetEmailSent();
          })
          .catch(() =>
            message.error('Sorry, there was an error sending the email. Please try again later.')
          );
      }}
    >
      Send Email
    </Button>
  );

  return (
    <Modal
      title={!emailAlreadySent ? 'Password Reset Required' : 'Error using New Password'}
      open={visible}
      onOk={() => {
        setVisible(false);
        setEmailSent(true);
      }}
      onCancel={() => setVisible(false)}
      cancelButtonProps={{ hidden: true }}
    >
      <p>Recently we migrated all Aloa accounts to a new authentication provider.</p>
      <p>After this migration, you only need one login for all of Aloa.</p>
      {!emailAlreadySent ? (
        <p>
          As part of this migration, users are required to reset their passwords. You can use your
          same password, but you will still need to update it from a password reset email.
        </p>
      ) : (
        <div>
          <p>
            <strong>
              It looks like you already tried to update your password. Please confirm you entered
              your password correctly on this page.
            </strong>
          </p>
        </div>
      )}

      {!emailSent ? (
        <div style={{ textAlign: 'center' }}>
          <p>
            {emailAlreadySent
              ? `If you did not get an email, click below to try sending again to `
              : `Click the button below to send an email to `}
            <strong>{`${email}`}</strong>
          </p>
          <p>{emailResetButton}</p>
        </div>
      ) : (
        <p>
          {`Email sent to `}
          <strong>{email}</strong>
          {`! Check your inbox for the link to reset your password.`}
        </p>
      )}

      {emailAlreadySent && (
        <p style={{ textAlign: 'center' }}>
          Please contact us if you continue to have problems signing in.
        </p>
      )}
    </Modal>
  );
}
