import React, { useState, useEffect } from 'react';
// import { connect } from 'react-redux';
import { message, Button, Icon } from 'antd';
// import { addPlaidSource, addCreditCard, addBankAccount } from '../../../actions/user';
// import { showMilestoneActions } from '../../../actions/appDialog';
import { TbCreditCard } from 'react-icons/tb';
import addBankImg from '../../../images/add_bank.png';
import addCcImg from '../../../images/add_cc.png';
import AddCreditCard from './AddCreditCard';
import AddBankAccount from './AddBankAccount';
import BankForm from './AddBankAccount/BankForm';
import Complete from './Complete';
import './AddPaymentDialog.css';
import achImage from '../../../images/ach_image.png';
import ccImage from '../../../images/cc_image.png';

import { addCreditCard, addPlaidSource, addBankAccount } from '../../../api/users';
import { PaymentMethodOptionCard } from '../../Onboarding';

/**
 * List of different active states for convenience
 * Component usage - this.setState(activeStates.example);
 */
const activeStates = {
  null: null,
  bankComplete: 'bank_complete',
  ccComplete: 'cc_complete',
  bankNoPlaid: 'bank_no_plaid',
  bank: 'bank',
  cc: 'cc',
  bankWaitForVerification: 'bank_wait_for_verification',
};

/**
 * Component to let user add payment
 * Path - /addPayment
 */
export default function AddPaymentDialogContent({
  currentUser,
  userId,
  visible,
  callback,
  refreshUser,
  onboarding = false,
}) {
  const [active, setActive] = useState(activeStates.null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  // Reset to initial state when visibility is set to false
  useEffect(() => {
    if (!visible) setTimeout(() => setActive(activeStates.null), 1000);
  }, [visible]);

  const cancelActive = () => {
    setActive(activeStates.null);
    callback(paymentMethod);
  };

  const handleAddPlaidSource = (token, metadata) =>
    new Promise((resolve, reject) => {
      addPlaidSource(currentUser, userId, token, metadata)
        .then(response => {
          setPaymentMethod(response);
          setActive(activeStates.bankComplete);
          refreshUser();
          resolve(response);
        })
        .catch(error => {
          message.error(error.message);
          reject(error);
        });
    });

  const handleAddBankAccount = bankData =>
    new Promise((resolve, reject) => {
      addBankAccount(currentUser, userId, bankData)
        .then(response => {
          setPaymentMethod(response);
          setActive(activeStates.bankWaitForVerification);
          refreshUser();
          resolve(response);
        })
        .catch(error => {
          message.error(error.message);
          reject(error);
        });
    });

  const handleAddCreditCard = token =>
    new Promise((resolve, reject) => {
      addCreditCard(currentUser, userId, token)
        .then(response => {
          setPaymentMethod(response);
          setActive(activeStates.ccComplete);
          refreshUser();
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          if (error.type !== undefined) {
            message.error(error.message);
          } else {
            message.error(
              'Looks like something is wrong with your card... Try checking your input.'
            );
          }
          reject(error);
        });
    });

  return (
    <>
      {active === null && (
        <>
          <div className="flex flex-col gap-2 items-center pt-8">
            <div className="text-2xl font-medium">👋 Add a payment method</div>
            <div className="text-neutral-500">You can edit this and add more later</div>
          </div>
          <div className="flex flex-col gap-6 items-center pt-8">
            <PaymentMethodOptionCard
              icon={
                <img src={achImage} className="w-16 h-16 rounded-lg overflow-hidden bg-[#E1EFF2]" />
              }
              name={'ACH'}
              description={'No processing fees'}
              action={() => {
                setActive(activeStates.bank);
              }}
            />

            <PaymentMethodOptionCard
              icon={
                <img src={ccImage} className="w-16 h-16 rounded-lg overflow-hidden bg-[#E1EFF2]" />
              }
              name={'Credit card'}
              description={'2.9% + 30¢ fee'}
              action={() => {
                setActive(activeStates.cc);
              }}
            />
          </div>
        </>
      )}
      {active === activeStates.cc && (
        <AddCreditCard
          currentUser={currentUser}
          handleAddCreditCard={handleAddCreditCard}
          cancelActive={() => {
            setActive(activeStates.null);
          }}
        />
      )}
      {active === activeStates.bank && (
        <AddBankAccount
          currentUser={currentUser}
          handleAddBankAccount={handleAddBankAccount}
          handleAddPlaidSource={handleAddPlaidSource}
          addNonPlaidBank={() => setActive(activeStates.bankNoPlaid)}
          cancelActive={() => {
            setActive(null);
          }}
        />
      )}
      {active === activeStates.bankNoPlaid && (
        <BankForm
          submit={handleAddBankAccount}
          cancelActive={() => {
            setActive(activeStates.bank);
          }}
        >
          <Button
            className="add-payment-cancel-button"
            onClick={() => setActive(activeStates.bank)}
          >
            Cancel
          </Button>
        </BankForm>
      )}
      {active === activeStates.ccComplete && (
        <Complete
          cancelActive={() => {
            if (onboarding) {
              // Redirect to the homepage
              window.location.href = '/';
            } else {
              setActive(activeStates.null);
              cancelActive();
            }
          }}
          complete="Credit Card"
        />
      )}
      {active === activeStates.bankComplete && (
        <Complete
          cancelActive={() => {
            if (onboarding) {
              // Redirect to the homepage
              window.location.href = '/';
            } else {
              setActive(activeStates.null);
              cancelActive();
            }
          }}
          complete="Bank Account"
        />
      )}
      {active === activeStates.bankWaitForVerification && (
        <Complete
          cancelActive={() => {
            if (onboarding) {
              // Redirect to the homepage
              window.location.href = '/';
            } else {
              setActive(activeStates.null);
              cancelActive();
            }
          }}
          complete="Bank Account"
          microDeposit
        />
      )}
    </>
  );
}

const titleForActiveState = active => {
  if (active === activeStates.cc) {
    return (
      <>
        Add New Credit Card
        <TbCreditCard style={{ marginLeft: '8px' }} type="credit-card" />
      </>
    );
  }
  if (active === activeStates.bank || active === activeStates.bankNoPlaid) {
    return (
      <>
        Add New Bank Account
        <div style={{ marginLeft: '8px', verticalAlign: '-0.1em' }} />
      </>
    );
  }
  return <>Add New Payment Method</>;
};
