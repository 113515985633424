/* eslint-disable no-console */
import React, { useState } from 'react';
import { Button, Avatar, message, Icon, Input } from 'antd';
import { LoadingOutline } from '@ant-design/icons';
import firebase from '../../firebase';
import Card from '../Card';
import { getBase64, id, makeImageData } from '../../utils';
import { updateUser } from '../../api/users';

// Loading icon from Ant

export default function AccountInfoCard({ currentUser, ids }) {
  const [changes, setChanges] = useState({ ...currentUser });
  const [newEmail, setNewEmail] = useState(currentUser.email);
  const [avatarLoading, setAvatarLoading] = useState(false);

  const handleClick = newMessage => {
    console.log(newMessage);
  };

  const handleChange = (event, type) => {
    if (type === 'name') {
      changes.name = event.target.value;
    }

    if (type === 'company') {
      changes.company = event.target.value;
    }

    // Do something special for email
    let tempNewEmail = newEmail;
    if (type === 'email') {
      tempNewEmail = event.target.value;
    }

    setChanges({ ...changes });
    setNewEmail(tempNewEmail);
  };

  // TODO: Abstract this to actions
  // Do as part of SSO - going to have to migrate accounts
  const imageUpload = e => {
    setAvatarLoading(true);
    const file = e.target.files[0];
    if (file.size > 3000000) {
      message.error('Sorry, file must be less than 3 Mb');
      setAvatarLoading(false);
      return;
    }
    getBase64(file, base64 => {
      // eslint-disable-next-line no-undef
      localStorage.fileBase64 = base64;
      // console.log("file stored", base64);
    });

    // Create a root reference
    const storageRef = firebase.storage().ref();

    const randomId = id();
    const uploadTask = storageRef.child(`${currentUser.id}/${randomId}.jpg`).put(file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded
        // and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
        switch (snapshot.state) {
          // case firebase.storage.TaskState.PAUSED: // or 'paused'
          //   console.log('Upload is paused');
          //   break;
          // case firebase.storage.TaskState.RUNNING: // or 'running'
          //   console.log('Upload is running');
          //   break;
          default:
            break;
        }
      },
      () => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          handleClick('Image Ready');
          // eslint-disable-next-line no-param-reassign
          const imageData = makeImageData(downloadURL);
          changes.image = imageData.image;
          changes.image_thumbnail = imageData.image_thumbnail;

          setAvatarLoading(false);
          setChanges({ ...changes });
        });
      }
    );
  };

  const saveAccountInfoChanges = () => {
    const changesToSave = {};
    if (changes.name !== currentUser.name) {
      changesToSave.name = changes.name;
    }
    if (changes.company !== currentUser.company) {
      changesToSave.company = changes.company;
    }
    if (changes.image !== currentUser.image) {
      changesToSave.image = changes.image;
    }
    if (changes.image_thumbnail !== currentUser.image_thumbnail) {
      changesToSave.image_thumbnail = changes.image_thumbnail;
    }

    if (Object.keys(changesToSave).length > 0) {
      updateUser(changesToSave, ids)
        .then(result => {
          message.success(result);
        })
        .catch(error => {
          message.error(error);
        });
    } else {
      message.info('All info up to date');
    }
  };

  return (
    <SettingsCard name="Account Info">
      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
        }}
      >
        Account Image
      </div>
      <label htmlFor="imageFile" style={{ cursor: 'pointer' }}>
        <input
          style={{ display: 'none' }}
          type="file"
          id="imageFile"
          name="imageFile"
          onChange={imageUpload}
        />
        {!avatarLoading && changes.image && (
          <Avatar size={100} src={changes.image} style={{ cursor: 'pointer' }} />
        )}
        {!avatarLoading && !changes.image && (
          <Avatar
            size={100}
            style={{
              color: '#f56a00',
              backgroundColor: '#fde3cf',
              verticalAlign: 'middle',
              cursor: 'pointer',
            }}
          >
            Change
          </Avatar>
        )}
        {avatarLoading && (
          <Avatar style={{ backgroundColor: '#fff' }} size={100}>
            Loading...
          </Avatar>
        )}
      </label>
      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        Name
      </div>
      <input
        value={changes.name}
        className="line-text-box"
        onInput={e => handleChange(e, 'name')}
        onChange={() => {}}
      />
      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        Company
      </div>
      <input
        value={changes.company}
        className="line-text-box"
        onInput={e => handleChange(e, 'company')}
        onChange={() => {}}
      />
      <div
        style={{
          fontSize: '17px',
          fontWeight: 500,
          fontFamily: 'Avenir Next',
          marginTop: '20px',
        }}
      >
        Email
      </div>

      <Input
        disabled
        value={newEmail}
        className="line-text-box"
        onInput={e => handleChange(e, 'email')}
        onChange={() => {}}
        style={{ color: '#aaa' }}
      />
      {/* {props.currentUser.acc_type === 2 &&
          <div>
            <div style={{fontSize: "17px",fontWeight: 500,
            fontFamily: "Avenir Next", marginTop: "20px"}}>
              Developer Mode
            </div>
            <Switch onChange={onChange.bind(this)} checked={currentUser.devEnabled} />
          </div>
        } */}

      <div className="account-action-button">
        <Button type="primary" onClick={saveAccountInfoChanges}>
          Save Account Info
        </Button>
      </div>
      <div style={{ clear: 'right' }} />
    </SettingsCard>
  );
}

export function SettingsCard({ name, children }) {
  return (
    <div>
      <div
        style={{
          fontSize: 16,
          fontWeight: 500,
          paddingBottom: 15,
          color: '#4e4e4e',
          fontFamily: 'Brown',
          // fontWeight: 500,
          textAlign: 'left',
        }}
      >
        {name}
      </div>

      <Card>
        <div style={{ textAlign: 'left' }}>{children}</div>
      </Card>
    </div>
  );
}
