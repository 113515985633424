import firebase from 'firebase';
import { env } from './constants';

const { Timestamp } = firebase.firestore;

export const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const numToMonth = n => {
  switch (n) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    case 11:
      return 'Dec';
    default:
      return '';
  }
};

export const sumCost = delivs => {
  let cost = 0;

  for (let i = 0; i < delivs.length; i += 1) {
    cost += delivs[i].cost;
  }

  return cost;
};

export const getBase64 = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const convertDateToUTC = date =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

export const momentToTimestamp = time => {
  const timestamp = new Timestamp(time.unix(), time.milliseconds() * 1000);
  return timestamp;
};

export const id = () =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15);

export const isDevEnv = () => {
  return env.env === 'dev';
};

export const isDevServer = () => {
  return env.isDevServer;
};

export const firebaseLinkToGoogleLink = link => {
  const firebaseStorageLink = /.*firebasestorage\.googleapis\.com\/v0\/b\/aloa-devpay.appspot.com\/o/i;
  const queryString = /\?alt.*/i;
  const googleStorageLink = 'https://storage.googleapis.com/aloa-devpay.appspot.com';
  return link.replace(firebaseStorageLink, googleStorageLink).replace(queryString, '');
};

// Methods to change firebase download url to a publicly available one
// And to save the thumbnail link
const getImageThumbnail = (link, imageId) =>
  link.replace(`${imageId}.jpg`, `thumbnails/${imageId}_200x200.jpg`);

const getPhotoId = downloadUrl => {
  const photoName = downloadUrl.match(/\/[^/]*\.jpg/i)[0];
  return photoName.replace('/', '').replace('.jpg', '');
};

export const makeImageData = firebaseDownloadUrl => {
  const link = decodeURIComponent(firebaseDownloadUrl);
  const photoId = getPhotoId(link);
  const googleUrl = firebaseLinkToGoogleLink(link);

  return {
    image: googleUrl,
    image_thumbnail: getImageThumbnail(googleUrl, photoId),
  };
};

export const currencyFormat = num => {
  return `$${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};
